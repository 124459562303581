import tw from "tailwind.macro"
import React from "react"
import SEO from "../components/seo"
import abductionIllustration from "../svgs/abduction-illustration.svg"
import { Global, css } from "@emotion/core"

const Wrapper = tw.div`
  flex items-center justify-center w-full flex-col bg-white px-10 sm:px-10 md:px-10 lg:px-20 xl:px-20
`

function NotFoundPage() {
  return (
    <Wrapper className="font-face" >
    <Global styles={styles} />
      <SEO title="404: Not found" />
      <div>
        <img
          src={abductionIllustration}
          className="block mx-auto w-full"
          alt="Ghost getting abducted by aliens"
        />
        <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Looks like this page is a ghost that got abducted by aliens...
        </h2>
      </div>
      </Wrapper>
  );
}

export default NotFoundPage;

const styles = css`
  .font-face {
    font-family: 'Chromatica', Helvetica, Arial, sans-serif; /* 1 */
    text-rendering: optimizeLegibility;
	  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
    line-height: 1.618; /* 2 */
    -ms-text-size-adjust: 100%; /* 3 */
    -webkit-text-size-adjust: 100%; /* 3 */
    font-size: 14px;
    @media 
    (-webkit-min-device-pixel-ratio: 1.5), 
    (min-resolution: 144dpi){ 
        /* Retina-specific stuff here */
        font-size: 14px;
    }
  }
`;